<template>
  <div class="charts">
    <ul class="device-list">
      <li class="device-item" v-for="item in device_List" :key="item.deviceId" :style="deviceStyle(item)" @click="showDeviceInfos(item)">
        <div class="device-dialog" :style="deviceStyle(item.dialog)">
          <div class="device">
            <div :class="`${item.status}`"></div>
            <div class="device-detail">
              <div
                class="status"
                :style="`color:${
                  item.status === 'RUNNING_STATE' ? '#19A572' : item.status === 'FREE_STATE' ? '#f39800' : item.status === 'OFFLINE_STATE' ? '#909399' : '#EA3837'
                } ; font-family: blod;`"
              >
                {{ item.status === 'RUNNING_STATE' ? '运行中' : item.status === 'FREE_STATE' ? '空闲' : item.status === 'OFFLINE_STATE' ? '离线' : '故障' }}
              </div>
              <div class="name" :title="item.name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <DeviceInfo ref="info" />
  </div>
</template>

<script>
import device_ago from '../config.js';
import DeviceInfo from './info.vue';
export default {
  components: { DeviceInfo },
  data() {
    return {
      device_ago,
      device_List: [],
      deviceIds: []
    };
  },
  mounted() {
    this.init();
    setInterval(() => {
      this.init();
    }, 10 * 1000);
  },
  methods: {
    async init() {
      this.deviceIds = [];
      this.device_ago.forEach(item => {
        this.deviceIds.push(item.deviceId);
      });
      const res = await this.$apis.longjing.DeviceStatus({ deviceIds: this.deviceIds });
      this.device_ago.forEach(item => {
        res.forEach(ele => {
          // eslint-disable-next-line eqeqeq
          if (item.deviceId == `${ele.id}`) {
            item.status = ele.runningState;
          }
        });
      });
      this.device_List = this.device_ago;
    },
    deviceStyle({ width, height, left, top }) {
      return {
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`
      };
    },
    async showDeviceInfos(item) {
      this.$refs.info.show(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.device-dialog {
  position: absolute;
  background: url('../image/dialog.png') no-repeat;
  .device {
    width: 136px;
    height: 60px;
    padding: 10px 5px;
    display: flex;
    .RUNNING_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/green.png') no-repeat center/cover;
    }
    .FREE_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/orange.png') no-repeat center/cover;
    }
    .OFFLINE_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/grey.png') no-repeat center/cover;
    }
    .ALARM_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/red.png') no-repeat center/cover;
    }
    .device-detail {
      flex: 1;
      .status {
        font-style: 16px;
        font-weight: bold;
      }
      .name {
        font-family: stand;
        color: #fff;
        font-style: 14px;
        overflow: hidden;
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
